////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(0, 0, 0, 1);
}
.bg-secondaryColor {
  background-color: rgba(0, 0, 0, 1);
}
.bg-thirdColor {
  background-color: rgba(178, 143, 69, 1);
}
.bg-fourthColor {
  background-color: rgba(178, 143, 69, 1);
}
.bg-fifthColor {
  background-color: rgba(249, 249, 249, 1);
}
.bg-sixthColor {
  background-color: rgb(0, 0, 0);
}
.bg-seventhColor {
  background-color: rgb(0, 0, 0);
}
